import castArray from 'lodash/castArray';
import { parse } from 'querystring';
import {
  LeaderboardQueryStrings,
  LEADERBOARD_TYPE,
  Version,
} from '../types/leaderboard';
import { HomePageRaid, RAID } from '../types/raid-report';

const versionTranslations = {
  [Version.All]: 'Total',
  [Version.Normal]: 'Normal',
  [Version.Prestige]: 'Heroic',
  [Version.Legacy]: 'Legacy',
  [Version.Master]: 'Master',
};

const typeTranslations = {
  [LEADERBOARD_TYPE.WORLDS_FIRST]: `World's First`,
  [LEADERBOARD_TYPE.CLEAR]: 'Full Clears',
  [LEADERBOARD_TYPE.SPEED]: 'Speedrun',
  [LEADERBOARD_TYPE.SHERPA]: 'Sherpas',
};

const totalLabel = 'Total';
const totalSpeedLabel = 'Cumulative';

export const getLabel = (
  type: LEADERBOARD_TYPE,
  raid: RAID,
  version: Version
) => {
  if (type !== LEADERBOARD_TYPE.SPEED || version !== Version.All) {
    return versionTranslations[version];
  }
  return raid === RAID.ALL ? totalSpeedLabel : 'All';
};

export const getLink = (
  type: LEADERBOARD_TYPE,
  raid: RAID,
  version: Version
) => {
  let link = `/leaderboard/${type}/${raid}`;
  if (version !== Version.All) {
    link += `/${version}`;
  }
  return link;
};

export const getTitle = (
  type: LEADERBOARD_TYPE,
  raid: HomePageRaid,
  version: Version
) => {
  let title = raid.displayValue;
  if (raid.raid === RAID.ALL) {
    title = type === LEADERBOARD_TYPE.SPEED ? totalSpeedLabel : totalLabel;
  }
  if (
    (raid.raid !== RAID.ALL && type === LEADERBOARD_TYPE.CLEAR) ||
    (version !== Version.Normal && version !== Version.All)
  ) {
    title += ` ${versionTranslations[version]}`;
  }
  title += ` ${typeTranslations[type]}`;
  return title;
};

export const getSubtitle = (
  type: LEADERBOARD_TYPE,
  raid: HomePageRaid,
  version: Version
) => {
  return '';
};

export const parseLeaderboardQueryString = (
  search: string
): LeaderboardQueryStrings => {
  const queryString = search.substring(1);
  const parsedQuery = parse(queryString);
  const page = Number(parsedQuery.page);
  const pageSize = Number(parsedQuery.pageSize);
  const membershipId = parsedQuery.membershipId as string;
  const queryMembershipTypes = castArray(parsedQuery.membershipType)
    .filter(Boolean)
    .map(Number)
    // ie11 doesn't support Number.isFinite, and it's mapped to a Number above
    // eslint-disable-next-line no-restricted-globals
    .filter(isFinite);
  const membershipType = queryMembershipTypes.length
    ? queryMembershipTypes
    : undefined;
  return { page, pageSize, membershipId, membershipType };
};
