import {
  BadgeConfiguration,
  BestPlayerCountBadgeConfiguration,
  WorldFirstBadgeConfiguration,
} from '../types/raid-report';

export const isAccountCountBadgeConfiguration = (
  config?: BadgeConfiguration | BestPlayerCountBadgeConfiguration
): config is BestPlayerCountBadgeConfiguration => {
  return (
    !!config &&
    typeof (config as BestPlayerCountBadgeConfiguration).accountCount ===
      'number'
  );
};

const getBossTooltip = (extra: string) => (boss: string) =>
  `Defeated ${boss} ${extra}`;

const getFlawlessTooltip = (extra: string, fireteam: string = '') =>
  `Completed the whole dungeon ${extra} with no ${fireteam} deaths`;

export const FLAWLESS_BADGE_CONFIGURATION: BadgeConfiguration = {
  label: 'Flawless',
  tooltip: (boss: string) => getFlawlessTooltip('', 'fireteam'),
};

export const DAY_ONE_BADGE_CONFIGURATION: WorldFirstBadgeConfiguration = {
  hours: 24,
  label: 'Day One',
  tooltip: getBossTooltip('within 24 hours of launch'),
};

export const createWeekOneBadgeConfiguration = (
  hours: number = 7 * 24
): WorldFirstBadgeConfiguration => ({
  hours,
  label: 'Week One',
  tooltip: getBossTooltip('before the first weekly reset'),
});

export const SOLO_BADGE_CONFIGURATION: BestPlayerCountBadgeConfiguration = {
  accountCount: 1,
  label: 'Solo',
  tooltip: getBossTooltip('alone'),
  flawlessTooltip: getFlawlessTooltip('alone'),
};

export const DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION: BestPlayerCountBadgeConfiguration[] =
  [SOLO_BADGE_CONFIGURATION];

export const SOLO_FLAWLESS_BADGE_CONFIGURATION: BadgeConfiguration = {
  label: 'Solo Flawless',
  tooltip: () => getFlawlessTooltip('alone'),
};
