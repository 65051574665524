import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import minBy from 'lodash/minBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import {
  createSelector,
  OutputParametricSelector,
  ParametricSelector,
} from 'reselect';
import { RootState } from '..';
import { FLAWLESS_BADGE_CONFIGURATION } from '../../constants/badge-configuration';
import { INDIVIDUAL_RAIDS } from '../../constants/destiny';
import { SUBSCRIPTION_MAP } from '../../constants/subscription';
import {
  CharacterComponent,
  DestinyRecordState,
  RecordsComponent,
} from '../../types/account-summary';
import { Activity } from '../../types/activity';
import { EmptyAggregateActivity } from '../../types/aggregate-activity';
import { CompleteMembershipRequest } from '../../types/membership-info';
import { RaidProps, RaidReportActivity } from '../../types/raid-report';
import {
  combineAggregateActivities,
  getAccountCount,
  getActivityCompleted,
  getCurrentResetDate,
  getFastestCompletionSecondsForActivity,
  getFresh,
  getInstanceId,
  getResetDateForActivity,
} from '../../utils/app-helper';
import { ONE_HOUR_IN_SEC } from '../../utils/ui-helper';
import { getRaidFilter } from '../settings/selectors';

function createCachedRaidSelector<S, P, R1, T>(
  selector: ParametricSelector<S, P, R1>,
  combiner: (res: R1) => T
): OutputParametricSelector<S, P, T, (res: R1) => T>;
function createCachedRaidSelector<S, P, R1, R2, T>(
  selector1: ParametricSelector<S, P, R1>,
  selector2: ParametricSelector<S, P, R2>,
  combiner: (res1: R1, res2: R2) => T
): OutputParametricSelector<S, P, T, (res1: R1, res2: R2) => T>;
function createCachedRaidSelector<S, P, R1, R2, R3, T>(
  selector1: ParametricSelector<S, P, R1>,
  selector2: ParametricSelector<S, P, R2>,
  selector3: ParametricSelector<S, P, R3>,
  combiner: (res1: R1, res2: R2, res3: R3) => T
): OutputParametricSelector<S, P, T, (res1: R1, res2: R2, res3: R3) => T>;

function createCachedRaidSelector(...funcs) {
  return (createCachedSelector as any)(...funcs)(
    (state: RootState, props: RaidProps) => props.raid.raid
  );
}

const getRaidReport = (state: RootState) => {
  return state.raidReport;
};

export const getUserInfo = (state: RootState) => {
  return getRaidReport(state).accountSummary.profile.data.userInfo;
};

const getRaid = (state: RootState, props: RaidProps) => {
  return props.raid;
};

const getRaidVersions = (state: RootState, props: RaidProps) => {
  return props.raid.versions;
};

const getRaidFlawlessRecordHash = (state: RootState, props: RaidProps) => {
  return props.raid.flawlessRecordHash;
};

const getRaidSoloRecordHash = (state: RootState, props: RaidProps) => {
  return props.raid.soloRecordHash;
};

const getRaidSoloFlawlessRecordHash = (state: RootState, props: RaidProps) => {
  return props.raid.soloFlawlessRecordHash;
};

export const getFetchingRaidReportStats = (state: RootState) => {
  return getRaidReport(state).fetchingRaidReportStats;
};

export const getRaidReportStats = (state: RootState) => {
  return getRaidReport(state).raidReportStats;
};

export const getRaidReportActivities = (state: RootState) => {
  return getRaidReportStats(state).activities;
};

export const getClearsRank = (state: RootState) => {
  return getRaidReportStats(state).clearsRank;
};

export const getSpeedRank = (state: RootState) => {
  return getRaidReportStats(state).speedRank;
};

export const getSubscriptionBadge = (state: RootState) => {
  return getRaidReportStats(state)
    .activeSubscriptions?.map((tier) => SUBSCRIPTION_MAP[tier])
    .filter((sub) => sub?.features.addsProfileBadge)
    .sort((prev, curr) => curr!.tier - prev!.tier)[0];
};

export const getRaidVersionHashes = createCachedRaidSelector(
  getRaidVersions,
  (versions) => {
    return versions.map((version) => version.activityHashes);
  }
);

export const getRaidHashes = createCachedRaidSelector(
  getRaidVersionHashes,
  (versionHashes) => {
    return versionHashes.reduce((a, b) => a.concat(b), []);
  }
);

export const getRaidReportRaidVersionActivities = createCachedRaidSelector(
  getRaidVersionHashes,
  getRaidReportActivities,
  (versionHashes, activities) => {
    return versionHashes.map((hashes) =>
      activities.filter((a) => hashes.includes(a.activityHash))
    );
  }
);

export const getRaidReportRaidVersionFastestRuns = createCachedRaidSelector(
  getRaidReportRaidVersionActivities,
  (versionActivities) => {
    return versionActivities.map((versionActivity) => {
      return minBy(
        versionActivity.map((a) => a.values.fastestFullClear),
        (b) => b && b.value
      );
    });
  }
);

export const getRaidReportRaidFullRunsCountByVersion = createCachedRaidSelector(
  getRaidReportRaidVersionActivities,
  (versionActivities) => {
    return versionActivities.map((activities) =>
      activities.map((a) => a.values.fullClears).reduce((a, b) => a + b, 0)
    );
  }
);

export const getRaidReportRaidFastestRun = createCachedRaidSelector(
  getRaidReportRaidVersionFastestRuns,
  (versionFastestRuns) => {
    return minBy(versionFastestRuns, (b) => b && b.value);
  }
);

export const getTotalClears = createSelector(
  getRaidReportActivities,
  (activities) => {
    return activities.map((a) => a.values.clears).reduce((a, b) => a + b, 0);
  }
);

export const getRaidReportRaidActivities = createCachedRaidSelector(
  getRaidReportActivities,
  getRaidHashes,
  (activities, hashes) => {
    return activities.filter((activity) =>
      hashes.includes(activity.activityHash)
    );
  }
);

export const getRaidSherpas = createCachedRaidSelector(
  getRaidReportRaidActivities,
  (activities) => {
    return activities
      .map((activity) => activity.values.sherpaCount || 0)
      .reduce((a, b) => a + b, 0);
  }
);

export const getRaidFlawlessInstanceIds = createCachedRaidSelector(
  getRaidReportRaidActivities,
  (activities) => {
    const ans = new Set<string>();
    activities.forEach(({ values: { flawlessActivities = [] } }) => {
      flawlessActivities.forEach((d) => ans.add(d.instanceId));
    });
    return ans;
  }
);

export const getRaidLowAccountCountInstanceIds = createCachedRaidSelector(
  getRaidReportRaidActivities,
  (activities) => {
    const ans = new Set<string>();
    activities.forEach(({ values: { lowAccountCountActivities = [] } }) => {
      lowAccountCountActivities.forEach((d) => ans.add(d.instanceId));
    });
    return ans;
  }
);

export const getRaidVersionLowAccountCountDetails = createCachedRaidSelector(
  getRaidReportRaidVersionActivities,
  (versionActivities) => {
    return versionActivities.map((activities) => {
      return sortBy(
        activities
          .map((a) => a.values.lowAccountCountActivities || [])
          .reduce((a, b) => a.concat(b), []),
        [getAccountCount, getFresh, getInstanceId]
      )[0];
    });
  }
);

export const getRaidBestPlayerCountDetails = createCachedRaidSelector(
  getRaidVersionLowAccountCountDetails,
  (bestPerVersion) => {
    return sortBy(bestPerVersion, [getAccountCount, getFresh])[0];
  }
);

export const getRaidVersionFlawlessDetails = createCachedRaidSelector(
  getRaidReportRaidVersionActivities,
  (versionActivities) => {
    return versionActivities.map((activities) => {
      return sortBy(
        activities
          .map((a) => a.values.flawlessActivities || [])
          .reduce((a, b) => a.concat(b), []),
        [getAccountCount, getFresh, getInstanceId]
      )[0];
    });
  }
);

export const getRaidFlawlessDetails = createCachedRaidSelector(
  getRaidVersionFlawlessDetails,
  (bestPerVersion) => {
    return sortBy(bestPerVersion, [getAccountCount, getFresh])[0];
  }
);

export const getRaidFlawlessBadge = createCachedRaidSelector(
  getRaidFlawlessDetails,
  getRaid,
  (flawlessDetails, raid) => {
    const configuration =
      flawlessDetails &&
      raid.bestPlayerCountBadgeConfiguration.find((config) => {
        return flawlessDetails.accountCount === config.accountCount;
      });
    if (configuration) {
      return {
        configuration: {
          accountCount: configuration.accountCount,
          tooltip: () => configuration.flawlessTooltip,
          label: `${configuration.label} ${FLAWLESS_BADGE_CONFIGURATION.label}`,
        },
        activityDetails: flawlessDetails,
      };
    }
    return (
      flawlessDetails && {
        configuration: FLAWLESS_BADGE_CONFIGURATION,
        activityDetails: flawlessDetails,
      }
    );
  }
);

export const getRaidBestPlayerCountBadge = createCachedRaidSelector(
  getRaidBestPlayerCountDetails,
  getRaid,
  (activityDetails, raid) => {
    const configuration =
      activityDetails &&
      raid.bestPlayerCountBadgeConfiguration.find((config) => {
        return activityDetails.accountCount === config.accountCount;
      });
    return (
      configuration &&
      activityDetails && {
        configuration,
        activityDetails,
      }
    );
  }
);

export const getCharacterIds = createSelector(getRaidReport, (raidReport) => {
  return raidReport.accountStats.characters
    .map((character) => character.characterId)
    .sort();
});

export const getMembershipInfo = (state: RootState) => {
  return getRaidReport(state).membershipInfo;
};

export const getActivities = (state: RootState) => {
  return getRaidReport(state).activities;
};

export const getActivitiesByHash = createSelector(
  getActivities,
  (activities) => {
    return groupBy(activities, (a) => a.activityDetails.referenceId);
  }
);

export const getRaidActivitiesByVersion = createCachedRaidSelector(
  getRaidVersionHashes,
  getActivitiesByHash,
  (versionHashes, activitiesByHash) => {
    return versionHashes.map((hashes) =>
      hashes
        .map((hash) => activitiesByHash[hash] || [])
        .reduce((a, b) => a.concat(b), [])
    );
  }
);

export const getRaidActivities = createCachedRaidSelector(
  getRaidActivitiesByVersion,
  (activitiesByVersion) => {
    return activitiesByVersion.reduce((a, b) => a.concat(b), []);
  }
);

export const getRaidCompletedActivitiesByVersion = createCachedRaidSelector(
  getRaidActivitiesByVersion,
  (versionActivities) => {
    return versionActivities.map((activities) =>
      activities.filter((a) => getActivityCompleted(a.values))
    );
  }
);

const completedFilter = (a: Activity) => getActivityCompleted(a.values);

export const getRaidCompletedActivities = createCachedRaidSelector(
  getRaidActivities,
  (activities) => {
    return activities.filter(completedFilter);
  }
);

const seriousFailedFilter = (a: Activity) =>
  !getActivityCompleted(a.values) &&
  a.values.playerCount.basic.value > 2 &&
  a.values.activityDurationSeconds.basic.value > 5 * 60 &&
  a.values.kills.basic.value > 0;

export const getRaidSeriousFailedActivities = createCachedRaidSelector(
  getRaidActivities,
  (activities) => {
    return activities.filter(seriousFailedFilter);
  }
);

const identityFilter = (a: unknown) => !!a;
const defaultFilter = (a: Activity) =>
  completedFilter(a) || seriousFailedFilter(a);

export const getRaidFilterFunction = createCachedRaidSelector(
  getRaidFilter,
  getRaidFlawlessInstanceIds,
  getRaidLowAccountCountInstanceIds,
  (filter, flawlessIds, lowAccountIds) => {
    switch (filter) {
      case 'all':
        return identityFilter;
      case 'success':
        return completedFilter;
      case 'star':
        return (a: Activity) =>
          flawlessIds.has(a.activityDetails.instanceId) ||
          lowAccountIds.has(a.activityDetails.instanceId);
      case 'default':
      default:
        return defaultFilter;
    }
  }
);

export const getDisplayedActivities = createCachedRaidSelector(
  getRaidActivities,
  getRaidFilterFunction,
  (activities, filterFunction) => {
    return activities
      .filter(filterFunction)
      .sort((a, b) => moment.utc(a.period).diff(moment.utc(b.period)));
  }
);

export const getRaidCompletionPercentage = createCachedRaidSelector(
  getRaidCompletedActivities,
  getRaidSeriousFailedActivities,
  (completed, failed) => {
    return Math.round(
      (completed.length / (completed.length + failed.length)) * 100
    );
  }
);

export const getRaidBestActivity = createCachedRaidSelector(
  getRaidCompletedActivities,
  (activities) => {
    return minBy(activities, (a) => a.values.playerCount.basic.value);
  }
);

const getEndDate = ({ values: { firstClear } }: RaidReportActivity) => {
  if (!firstClear) return moment();
  return moment(firstClear.period).add(firstClear.duration, 's');
};

export const getRaidReportRaidSortedActivities = createCachedRaidSelector(
  getRaidReportRaidActivities,
  (activities) => {
    return activities
      .filter((a) => a.values.firstClear)
      .sort((a, b) => getEndDate(a).valueOf() - getEndDate(b).valueOf());
  }
);

export const getRaidWorldFirstBadge = createCachedRaidSelector(
  getRaidReportRaidSortedActivities,
  getRaid,
  (activities, raid) => {
    return raid.worldFirstBadgeConfiguration
      .map((config) => {
        const activity = activities[0];
        return (
          activity?.values.firstClear &&
          getEndDate(activity).diff(raid.launchTime, 's') <
            ONE_HOUR_IN_SEC * config.hours && {
            configuration: config,
            activityDetails: activity.values.firstClear,
          }
        );
      })
      .find((i) => i);
  }
);

export const getRaidCompletedActivitiesByVersionAndResetDate =
  createCachedRaidSelector(
    getRaidCompletedActivitiesByVersion,
    (versionActivities) => {
      return versionActivities.map((activities) =>
        groupBy(activities, getResetDateForActivity)
      );
    }
  );

export const getLootedRaidsByVersion = createCachedRaidSelector(
  getRaidCompletedActivitiesByVersionAndResetDate,
  (versionActivities) => {
    return versionActivities.map((activitiesByReset) => {
      return Object.keys(activitiesByReset)
        .map((reset) => uniqBy(activitiesByReset[reset], 'characterId').length)
        .reduce((a, b) => a + b, 0);
    });
  }
);

export const getWeeklyRaidCompletedActivitiesByVersion =
  createCachedRaidSelector(
    getRaidCompletedActivitiesByVersionAndResetDate,
    (versionActivities) => {
      return versionActivities.map(
        (activitiesByReset) => activitiesByReset[getCurrentResetDate()] || []
      );
    }
  );

export const getWeeklyRaidCompletedActivitiesByVersionAndCharacter =
  createCachedRaidSelector(
    getWeeklyRaidCompletedActivitiesByVersion,
    getRaidVersions,
    (weeklyRaidsByVersion, raidVersions) => {
      return weeklyRaidsByVersion.map((weeklyRaids, index) => {
        return {
          activities: groupBy(weeklyRaids, 'characterId'),
          version: raidVersions[index].displayValue,
        };
      });
    }
  );

export const getAggregateActivities = (state: RootState) => {
  return getRaidReport(state).aggregateActivities;
};

export const getFetchingAggregateActivities = (state: RootState) => {
  return getRaidReport(state).fetchingAggregateActivities;
};

export const getRaidVersionAggregateActivities = createCachedRaidSelector(
  getRaidVersionHashes,
  getAggregateActivities,
  (versionHashes, aggregateActivities) => {
    return versionHashes.map((hashes) =>
      aggregateActivities.filter((a) =>
        hashes.some((hash) => hash === a.activityHash)
      )
    );
  }
);

export const getRaidVersionCombinedAggregateActivities =
  createCachedRaidSelector(
    getRaidVersionAggregateActivities,
    (versionAggregateActivities) => {
      return versionAggregateActivities.map((aggregateActivities) =>
        aggregateActivities.reduce(
          combineAggregateActivities,
          EmptyAggregateActivity
        )
      );
    }
  );

export const getRaidVersionFastestRuns = createCachedRaidSelector(
  getRaidVersionCombinedAggregateActivities,
  getRaidCompletedActivitiesByVersion,
  (combinedAggregateActivities, activitiesByVersion) => {
    return combinedAggregateActivities.map((aggregateActivity, index) => {
      const fastest =
        getFastestCompletionSecondsForActivity(aggregateActivity).basic.value;
      return activitiesByVersion[index].find(
        (a) => a.values.activityDurationSeconds.basic.value === fastest
      );
    });
  }
);

export const getRaidCombinedAggregateActivities = createCachedRaidSelector(
  getRaidVersionCombinedAggregateActivities,
  (aggregateActivities) => {
    return aggregateActivities.reduce(
      combineAggregateActivities,
      EmptyAggregateActivity
    );
  }
);

export const getAllCombinedAggregateActivities = (state: RootState) => {
  return INDIVIDUAL_RAIDS.map((raid) =>
    getRaidCombinedAggregateActivities(state, { raid })
  );
};

export const getRaidFastestTime = createCachedRaidSelector(
  getRaidCombinedAggregateActivities,
  (combinedAggregateActivities) => {
    return getFastestCompletionSecondsForActivity(combinedAggregateActivities);
  }
);

export const getRaidFastestRun = createCachedRaidSelector(
  getRaidFastestTime,
  getRaidCompletedActivities,
  (fastestValue, activities) => {
    return activities.find(
      (a) =>
        a.values.activityDurationSeconds.basic.value ===
        fastestValue.basic.value
    );
  }
);

export const getRaidFullRunsByVersion = createCachedRaidSelector(
  getRaidCompletedActivitiesByVersion,
  getRaidVersionCombinedAggregateActivities,
  (activitiesByVersion, aggregateActivitiesByVersion) => {
    return activitiesByVersion.map((activities, index) => {
      const fastest = getFastestCompletionSecondsForActivity(
        aggregateActivitiesByVersion[index]
      ).basic.value;
      return fastest
        ? activities.filter(
            (a) => a.values.activityDurationSeconds.basic.value >= fastest
          )
        : [];
    });
  }
);

export const getRaidReportRaidFullRunsByVersion = createCachedRaidSelector(
  getRaidCompletedActivitiesByVersion,
  getRaidReportRaidVersionFastestRuns,
  (activitiesByVersion, fastestRunsByVersion) => {
    return activitiesByVersion.map((activities, index) => {
      const fastest = fastestRunsByVersion[index];
      return fastest
        ? activities.filter(
            (a) => a.values.activityDurationSeconds.basic.value >= fastest.value
          )
        : [];
    });
  }
);

export const getRaidFullRunsCountByVersion = createCachedRaidSelector(
  getRaidFullRunsByVersion,
  (fullRuns) => {
    return fullRuns.map((activities) => activities.length);
  }
);

export const getRaidFullRuns = createCachedRaidSelector(
  getRaidReportRaidFullRunsByVersion,
  (fullRunsByVersion) => {
    return fullRunsByVersion.reduce((a, b) => a.concat(b), []);
  }
);

export const getRaidFullRunsCount = createCachedRaidSelector(
  getRaidReportRaidFullRunsCountByVersion,
  (fullRunsByVersion) => {
    return fullRunsByVersion.reduce((a, b) => a + b, 0);
  }
);

export const getRaidMedianRunsByVersion = createCachedRaidSelector(
  getRaidReportRaidFullRunsByVersion,
  (activitiesByVersion) => {
    return activitiesByVersion.map(
      (activities) =>
        sortBy(activities, 'values.activityDurationSeconds.basic.value')[
          Math.floor(activities.length / 2)
        ]
    );
  }
);

export const getRaidMedianRun = createCachedRaidSelector(
  getRaidFullRuns,
  (activities) => {
    return sortBy(activities, 'values.activityDurationSeconds.basic.value')[
      Math.floor(activities.length / 2)
    ];
  }
);

export const getDestinyMemberships = (state: RootState) => {
  return getRaidReport(state).destinyMemberships;
};

export const getIsLoading = (state: RootState) => {
  return getRaidReport(state).isLoading;
};

export const getAccountSummary = (state: RootState) => {
  return getRaidReport(state).accountSummary;
};

export const getFetchingAccountSummary = (state: RootState) => {
  return getRaidReport(state).fetchingAccountSummary;
};

export const getAccountStats = (state: RootState) => {
  return getRaidReport(state).accountStats;
};

export const getFetchingAccountStats = (state: RootState) => {
  return getRaidReport(state).fetchingAccountStats;
};

const getAccountSummaryProfileRecords = (state: RootState) => {
  return getAccountSummary(state).profileRecords.data;
};

const getAccountSummaryCharacterRecords = (state: RootState) => {
  return getAccountSummary(state).characterRecords.data;
};

const getAccountSummaryRecords = createSelector(
  getAccountSummaryProfileRecords,
  getAccountSummaryCharacterRecords,
  (profileRecords, characterRecords) => {
    const records = characterRecords
      ? Object.keys(characterRecords).map((key) => characterRecords[key])
      : [];
    return profileRecords ? records.concat(profileRecords) : records;
  }
);

export const getAccountSummaryCharacters = createSelector(
  getAccountSummary,
  (accountSummary) => {
    return Object.keys(accountSummary.characters.data).map(
      (key) => accountSummary.characters.data[key]
    );
  }
);

export const getLastPlayedCharacter = createSelector(
  getAccountSummaryCharacters,
  (characters): CharacterComponent | undefined => {
    return characters.sort((a, b) =>
      moment.utc(b.dateLastPlayed).diff(moment.utc(a.dateLastPlayed))
    )[0];
  }
);

export const getSortedAccountSummaryCharacters = createSelector(
  getAccountSummaryCharacters,
  (characters) => {
    return sortBy(characters, 'characterId');
  }
);

export const getCharacterIdsWithFailure = createSelector(
  getAccountSummaryCharacters,
  (characters) => {
    return characters.map((character) => character.characterId).sort();
  }
);

export const getFetchingActivities = (state: RootState) => {
  return getRaidReport(state).fetchingActivities;
};

export const getCompleteMembershipRequest = createSelector(
  getCharacterIds,
  getMembershipInfo,
  (characterIds, membershipInfo): CompleteMembershipRequest => ({
    characterIds,
    membershipInfo,
  })
);

export const getCompleteMembershipRequestWithFailure = createSelector(
  getCharacterIdsWithFailure,
  getMembershipInfo,
  (characterIds, membershipInfo): CompleteMembershipRequest => ({
    characterIds,
    membershipInfo,
  })
);

const getCurrentMaxLight = createSelector(
  getAccountSummaryCharacters,
  (characters) => Math.max(...characters.map((character) => character.light))
);

const getBungieMaxLight = createSelector(
  getAccountStats,
  (accountStats): number =>
    get(
      accountStats.mergedAllCharacters,
      'merged.allTime.highestLightLevel.basic.value',
      0
    )
);

export const getMaxLight = createSelector(
  getCurrentMaxLight,
  getBungieMaxLight,
  (current, lifetime) => Math.max(current, lifetime)
);

const getRecordIsComplete = (
  records: RecordsComponent[],
  recordHash: number | undefined
) => {
  return (
    recordHash &&
    records.some((r) => {
      const record = r.records[recordHash];
      if (!record) return false;
      const { state, objectives } = record;
      const redeemed = Boolean(
        // eslint-disable-next-line no-bitwise
        state & DestinyRecordState.RecordRedeemed
      );
      const unlocked =
        // eslint-disable-next-line no-bitwise
        !(state & DestinyRecordState.ObjectiveNotCompleted);
      return objectives?.[0]?.complete || redeemed || unlocked;
    })
  );
};

export const getRaidFlawlessTriumph = createCachedRaidSelector(
  getAccountSummaryRecords,
  getRaidFlawlessRecordHash,
  getRecordIsComplete
);

export const getRaidSoloTriumph = createCachedRaidSelector(
  getAccountSummaryRecords,
  getRaidSoloRecordHash,
  getRecordIsComplete
);

export const getRaidSoloFlawlessTriumph = createCachedRaidSelector(
  getAccountSummaryRecords,
  getRaidSoloFlawlessRecordHash,
  getRecordIsComplete
);

export const getRaidReportRaidClearsCountByVersion = createCachedRaidSelector(
  getRaid,
  getRaidReportRaidVersionActivities,
  getRaidVersionCombinedAggregateActivities,
  (raid, versionActivities, combinedVersionActivities) => {
    if (raid.useBungieStats) {
      return combinedVersionActivities.map((activity) => {
        return activity.values.activityCompletions.basic.value;
      });
    }
    return versionActivities.map((activities) =>
      activities.map((a) => a.values.clears).reduce((a, b) => a + b, 0)
    );
  }
);

export const getRaidTotalCompletions = createCachedRaidSelector(
  getRaid,
  getRaidReportRaidClearsCountByVersion,
  getRaidCombinedAggregateActivities,
  (raid, clearsByVersion, combinedAggregateActivities) => {
    if (raid.useBungieStats) {
      return combinedAggregateActivities.values.activityCompletions.basic.value;
    }
    return clearsByVersion.reduce((a, b) => a + b, 0);
  }
);
